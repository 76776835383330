<template>
    <div v-if="active" class="fixed z-10 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity" @click="close()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div  id="modal" class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

            <modal-close @close="close()"/>

            <div class="text-left">
                <h3 class="modal-title text-2xl leading-6 font-bold mb-4" id="modal-headline">
                    {{$t('translations.components.modal-table-unavailable.title')}}
                </h3>
                <div class="mt-2">
                    <p class="text-base leading-5">
                        {{$t('translations.components.modal-table-unavailable.sentence-one')}} {{ table.attributes.name }} {{$t('translations.components.modal-table-unavailable.sentence-two')}} {{table.relationships.merged_table.attributes.name}}.
                    </p>
                    <p class="mt-2 text-base">
                        {{$t('translations.components.modal-table-unavailable.unlink-question')}}
                    </p>
                </div>
            </div>
            <div class="mt-5 flex items-center justify-between">
                    <a @click="unmerge()" class="button button-danger button-large mr-4 flex-grow">
                        {{$t('translations.components.modal-table-unavailable.yes-unlink')}}
                    </a>
                    <a @click="close()" class="button button-ghost button-large flex-grow">
                        {{$t('translations.components.modal-table-unavailable.no-cancel')}}
                    </a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

import ModalClose from "./ModalClose";

export default {
    components: {
        ModalClose,
    },
    data () {
        return {
            active: false,
            table: null,
        }
    },
    methods: {
        open(table) {
            this.table = table
            this.active = true
        },
        close() {
            this.active = false
        },
        async unmerge() {
            await axios.post('/tables/unmerge', {
                table_id: this.table.id,
            }).then(() => {
                this.$notify({ group: 'notifications', type: 'success', title: 'Tafel is ontkoppeld'}, 2000);
                this.$emit('unmerged')
                this.close()
            }).catch(() => {
                this.$notify({ group: 'notifications', type: 'error', title: 'Fout opgetreden', text: 'Oeps er ging iets fout..' }, 2000);
            })
        },
    },
}
</script>
