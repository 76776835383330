<template>
    <a  class="resetspending text-xs">
       <currency/>{{ vueNumberFormat(amount) }}
    </a>
    <svg v-on:click.stop.prevent @click="open()" class="h-5 w-5" fill="#eb5e1d" viewBox="0 0 20 20">
        <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd"/>
    </svg>

  <teleport to="#modal">
    <div v-if="active" class="fixed z-200 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity" @click="close()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div id="modal" class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <modal-close @close="close()"/>
            <div>
                <div class="text-left">
                    <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-5" id="modal-headline">
                        {{$t('translations.components.modal-table-reset-spending.title')}}
                    </h3>
                    <div class="mt-2">
                        <p class="text-sm leading-5">
                            {{$t('translations.components.modal-table-reset-spending.question-one')}} {{ table.attributes.name}} {{$t('translations.components.modal-table-reset-spending.question-two')}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-4">
                <a @click="onSubmit()" class="button button-large button-wide button-danger">
                    {{$t('translations.components.modal-table-reset-spending.reset')}}
                </a>
            </div>
        </div>
    </div>
  </teleport>
</template>

<script>
import axios from 'axios';
import ModalClose from "./ModalClose";

export default {
    props: ['table'],
    components: {
        ModalClose,
    },
    data () {
        return {
            active: false,
            amount: this.table.attributes.total_spend ? this.table.attributes.total_spend : 0,
        }
    },
    methods: {
        open() {
            if (this.table.attributes.merged) {
                return
            }
            this.active = true
        },
        close() {
            this.active = false
        },

        async onSubmit() {
            await axios.post('/tables/reset-spending', {
                type: 'table',
                table_id: this.table.id
            }).then(() => {
                this.amount = 0

                this.$notify({ group: "notifications", type: 'success', title: "Succes", text: "Spending van tafel " + this.table.attributes.name + " staat op 0" }, 2000)
                this.close()
            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            })
        },
    },
}
</script>

<style scoped>
.resetspending {

}

.z-200 {
    z-index: 200;
}
</style>
