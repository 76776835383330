<template>
    <a @click="open()" class="button button-blur">
        {{$t('translations.components.modal-table-reset-spending-all.placeholder')}}
    </a>

    <div v-if="active"
         class="fixed z-10 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity" @click="close()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div id="modal"
             class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
             role="dialog" aria-modal="true" aria-labelledby="modal-headline">

            <modal-close @close="close()" />

            <div class="sm:flex sm:items-start mb-5">
                <div class="mt-3 sm:mt-0 sm:text-left">
                    <h3 class="modal-title text-lg leading-6 font-bold text-2xl mb-4" id="modal-headline">
                        {{$t('translations.components.modal-table-reset-spending-all.title')}}
                    </h3>
                    <p class="text-sm leading-5 mb-4">
                        {{$t('translations.components.modal-table-reset-spending-all.confirm-question')}}
                    </p>
                </div>
            </div>
            <div class="">
                <a @click="onSubmit()" class="button button-danger button-large button-wide">
                    {{$t('translations.components.modal-table-reset-spending-all.reset')}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import ModalClose from "./ModalClose";

export default {
    props: ['table'],
    components: {
        ModalClose,
    },
    data () {
        return {
            active: false,
        };
    },
    methods: {
        open () {
            this.active = true;
        },
        close () {
            this.active = false;
        },

        async onSubmit () {
            await axios.post('/tables/reset-spending', { type: 'tables' }).then(() => {
                this.$emit('resetted');
                this.$notify({
                    group: "notifications",
                    type: 'success',
                    title: "Succes",
                    text: "Spending van tafels staan op 0",
                }, 2000);
                this.close();
            }).catch(() => {
                this.$notify({
                    group: "notifications",
                    type: 'error',
                    title: "Fout opgetreden",
                    text: "Oeps er ging iets fout..",
                }, 2000);
            });
        },
    },
};
</script>
