<template>
    <div v-if="active" class="fixed z-10 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity" @click="close()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div  id="modal" class="rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="absolute top-0 right-0 pt-4 pr-4">
                <a @click="close()" class="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Close">
                    <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                </a>
            </div>
            <div>
                <div class="mt-3 sm:mt-5">
                    <h3 class="modal-title text-2xl leading-6 font-medium" id="modal-headline">
                        {{$t('translations.components.modal-table-merge-confirm.title')}}
                    </h3>
                    <div class="mt-2">
                        <p class="text-base leading-5">
                            {{$t('translations.components.modal-table-merge-confirm.ask-for-merging')}}
                        </p>
                        <p class="mt-3 text-base italic">
                            {{$t('translations.components.modal-table-merge-confirm.question-one')}} <b>{{$t('translations.components.modal-table-merge-confirm.table')}} {{ table.attributes.name }}</b> {{$t('translations.components.modal-table-merge-confirm.question-two')}}.
                        </p>
                    </div>
                </div>
            </div>
            <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span class="flex w-full rounded-sm  shadow-sm sm:col-start-2">
                    <a @click="confirm()" class="button button-large button-danger w-full">
                        {{$t('translations.components.modal-table-merge-confirm.yes-merge')}}
                    </a>
                </span>
                <span class="mt-3 flex w-full rounded-sm  shadow-sm sm:mt-0 sm:col-start-1">
                    <a @click="cancel()" class="button button-ghost button-large w-full">
                        {{$t('translations.components.modal-table-merge-confirm.no-merge')}}
                    </a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            active: false,
            table: null,
        }
    },
    methods: {
        open(table) {
            this.table = table
            this.active = true
        },
        close() {
            this.active = false
        },
        cancel() {
            this.$emit('canceled')
            this.close()
        },
        confirm() {
            this.$emit('confirmed')
            this.close()
        },
    },
}
</script>
