<template>
    <div v-if="active" class="fixed z-10 bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center">
        <div class="fixed inset-0 transition-opacity" @click="close()">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div id="modal" class="rounded-sm text-left px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

            <modal-close @close="close()"/>

            <div>
                <div class="mt-3 sm:mt-5">
                    <h3 class="modal-title text-2xl leading-6 font-medium" id="modal-headline">
                        {{$t('translations.components.modal-table-claimed.title')}}
                    </h3>
                    <div class="mt-2">
                        <p class="text-base leading-5">
                            {{$t('translations.components.modal-table-claimed.sentence-one')}} {{ table.attributes.name }} {{$t('translations.components.modal-table-claimed.sentence-two')}}
                        </p>
                        <p class="mt-3 text-base italic">
                            {{$t('translations.components.modal-table-claimed.release-table')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import ModalClose from "./ModalClose";

export default {
    data () {
        return {
            active: false,
            table: null,
        }
    },
    components: {
        ModalClose,
    },
    methods: {
        open(table) {
            this.active = true
            this.table = table
        },
        close() {
            this.active = false
        },
    },
}
</script>
