<script>
import axios from "axios"
import ModalClose from "@/components/ModalClose"
import ModalTableResetSpending from "@/components/ModalTableResetSpending.vue"

export default {
  name: "SubtableModal",
  props: ["area_id", "area"],
  emits: ["close"],
  inject: ["tableSelect"],

  components: {
    ModalTableResetSpending,
    ModalClose,
  },

  data() {
    return {
      isOpen: false,
      tableId: null,
      subtables: [],
    }
  },

  mounted() {},

  methods: {
    open(tableId) {
      this.isOpen = true
      this.tableId = tableId

      this.fetchSubtables()
    },

    close() {
      this.isOpen = false
      this.tableId = null
      this.subtables = []
      this.$emit("close")
    },

    async newSubtable() {
      try {
        await axios.post(`/tables/${this.tableId}/child`)

        void this.fetchSubtables()
      } catch {
        this.$notify(
          {
            group: "notifications",
            type: "error",
            title: "Fout opgetreden",
            text: "Oeps er ging iets fout..",
          },
          2000
        )
      }
    },

    async fetchSubtables() {
      try {
        const response = await axios.get(`/tables/${this.tableId}/child`)

        this.subtables = Array.isArray(response.data?.data) ? response.data.data : []
      } catch {
        this.$notify(
          {
            group: "notifications",
            type: "error",
            title: "Fout opgetreden",
            text: "Oeps er ging iets fout..",
          },
          2000
        )
      }
    },

    isTableAvailable(table) {
      if (!table.attributes.active_clerk_restriction) {
        return true
      }

      if (!table.attributes.clerk_id) {
        return true
      }

      return String(table.attributes.clerk_id) === String(this.clerk.id)
    },
  },

  computed: {
    clerk() {
      return this.$store.getters["transaction/clerk"]
    },
  },
}
</script>

<template>
  <teleport to="#mobile-modal">
    <div
      v-if="isOpen"
      class="fixed z-40 inset-0 overflow-hidden sm:py-8"
    >
      <div
        class="fixed inset-0 transition-opacity"
        @click="close()"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        id="modal"
        class="w-full h-full inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-2xl"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div class="modal-content flex flex-col justify-start items-start h-full overflow-hidden">
          <modal-close
            @close="close()"
            class="top-0 right-0 rounded"
          />

          <div
            ref="modalRef"
            class="w-full flex-shrink-0 flex justify-between items-center mb-6"
          >
            <h3
              id="modal-headline"
              class="modal-title leading-6 font-bold text-2xl mb-0"
            >
              Kies sub-tafel:
            </h3>
          </div>

          <div class="w-full flex-1 flex flex-col justify-start items-start overflow-auto">
            <ul class="w-full grid grid-cols-3 gap-4 table-overview">
              <li
                v-for="(table, index) in subtables"
                :key="index"
                class="tile z-10"
                :class="{
                  'opacity-25': table.attributes.merged || !table.attributes.active_pos,
                }"
              >
                <div
                  @click="tableSelect(table)"
                  class=""
                >
                  <div class="content">
                    <div class="table-name">{{ table.attributes.name }}</div>
                    <div class="table-amount">
                      <div class="reset z-20">
                        <modal-table-reset-spending :table="table" />
                      </div>
                    </div>

                    <div
                      class="clock"
                      v-if="table.attributes.activated_at"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="transparent"
                        stroke="#ffcc00"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        class="w-5 h-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <div
                  @click="tableSelect(table)"
                  :class="[table.attributes.total > 0 ? 'bg-red-500' : 'bg-green-500']"
                  class="price"
                >
                  <currency />
                  {{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}
                </div>
              </li>

              <li>
                <button
                  class="list-item relative flex items-center justify-center rounded-lg overflow-hidden focus:outline-none"
                  @click.prevent="newSubtable"
                >
                  <span class="sr-only">Add</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M5 12h14" />
                    <path d="M12 5v14" />
                  </svg>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
